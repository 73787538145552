// @flow
import * as React from 'react'
import { Container, Col, Row, config, Hidden } from 'react-awesome-styled-grid'
import Badges from '@components/Badges'
import styled from 'styled-components'
import { P } from '@components/shared/typography'
import { colors } from '@components/shared/colors'
import { CTASecondaryLink } from '@components/shared'

export default function DriveToDownload (props: {dontShowPhoneImage?: boolean}): React.Node {
  return (
    <Section>
      <Container>
        <Row>
          {props.dontShowPhoneImage && <Col align="center" justify="center">
            <div>
              <TextAndCTAContainer $dontShowPhoneImage>
                <DipseaIconImg src="/images/dipsea-logo.png" alt="Dipsea icon" />
                <ListenText $dontShowPhoneImage>{`Find your\nfavorite fantasy.`}</ListenText>
                <CTASecondaryLink passHref={true} href={'/subscribe?f=2&promo=try7d'} color={colors.sand}>TRY DIPSEA</CTASecondaryLink>
              </TextAndCTAContainer>
              <Badges alignLeft={false} containerStyles={{ marginBottom: '60px' }} webApp={false}/>
            </div>
          </Col>}
          {props.dontShowPhoneImage !== true && <Col sm={8} md={8} lg={5}
            offset={{
              lg: 1
            }}
            align="center" justify="center">
            <div>
              <TextAndCTAContainer>
                <ListenText>{`Find your\nfavorite fantasy.`}</ListenText>
                <CTASecondaryLink passHref={true} href={'/subscribe?f=2&promo=try7d'} color={colors.sand}>TRY DIPSEA</CTASecondaryLink>
              </TextAndCTAContainer>
              <Badges alignLeft={true} containerStyles={{ marginBottom: '60px' }} webApp={false}/>
            </div>
          </Col>}
          {props.dontShowPhoneImage !== true && <Hidden xs sm md>
            <Col lg={5} align="center">
            <PhoneImage src='/images/drive-to-download-device.png' />
            </Col>
          </Hidden>}
        </Row>
      </Container>
    </Section>
  )
}

const DipseaIconImg = styled.img`
  width: 48px;
  height: 48px;
  margin-left: 12px;
    margin-right: 8px;
  z-index: 1;
  ${(props: any): string => config(props).media.md`
    width: 56px;
    height: 56px;
  `}
  ${(props: any): string => config(props).media.lg`
    width: 72px;
    height: 72px;
  `}
`

const TextAndCTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    margin-bottom: 18px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${colors.sand};
    
    ${(props: any): string => config(props).media.md`
      padding-bottom: 49px;
    `}
    ${(props: any): string => config(props).media.lg`
      ${props.$dontShowPhoneImage ? '' : 'text-align: left; align-items: flex-start;'}
      padding-bottom: 64px;
    `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const PhoneImage = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 360px;
  img {
    width: 100%;
  }
`
const Section = styled.section`
  padding-top: 35px;
  
  ${(props: any): string => config(props).media.lg`
     padding: 120px 0 0;
  `}
  ${(props: any): string => config(props).media.xl`
     padding: 140px 0 0;
  `}
`
const ListenText = styled(P)`
  font-family: 'Queens Condensed Light';
  font-style: normal;
  font-size: 45px;
  line-height: 49.5px;
  white-space: pre;
  margin: 20px 0;
  text-align: center;
  color: ${colors.sand};
  ${(props: any): string => config(props).media.xl`
    margin: 25px 0;
    white-space: unset;
  `}
  ${(props: any): string => config(props).media.lg`
    ${props.$dontShowPhoneImage ? 'font-size: 90px;' : 'font-size: 60px;'}
    ${props.$dontShowPhoneImage ? 'line-height: 90px;' : 'line-height: 70px;'}
    ${props.$dontShowPhoneImage ? 'text-align: center;' : 'text-align: left;'}
  `};
`
